
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkUserSubscriptionStatus } from '../utils/subscriptionFunc';

const PrivateRoute = ({ element }) => {
  const isLoggedIn = !!localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      toast.info("You must be logged in");
      navigate('/login', { state: { from: location } });
    }
  }, [isLoggedIn]);

  return isLoggedIn ? element : <Navigate to="/home" />;
};

const SubscribedRoute = ({ element }) => {
  const { isLoggedIn, isSubscribed, setIsSubscribed } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useAuth();

  useEffect(() => {
    const checkSubscription = async () => {
      if (profile) {
        const subscribed = await checkUserSubscriptionStatus(profile?.sub); 
        console.log('Subscribed');
        console.log(subscribed);
        setIsSubscribed(subscribed?.data?.isActive);
        // if(isSubscribed?.data?.isActive){
        //   navigate("/home", { state: { from: location } });
        // }else{
        //   toast.info("You must be subscribed");
        //   navigate('/subscribe', { state: { from: location } });
        // }
      } else {
        toast.info("You must be subscribed");
        navigate('/subscribe', { state: { from: location } });
      }
    };

    checkSubscription();
  }, [isLoggedIn, navigate, isSubscribed, setIsSubscribed]);

  return isSubscribed ? element : <Navigate to="/home" />;
};

export { PrivateRoute, SubscribedRoute };
