
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Lease from '../pages/Lease/Lease';
import Land from '../pages/Land/land';
import Appartments from '../pages/Apartments/Apartments';
import HouseForSale from '../pages/HouseForSale/HouseForSale';
import SubscriptionPage from '../pages/Subscription/Subscription';
import PaymentPage from '../pages/Subscription/Payment';
import { PrivateRoute, SubscribedRoute } from './PrivateRoute';
import LeaseDetails from '../pages/Lease/components/LeaseDetails';
import DetailedCard from '../components/CardDetails';
import PropertyForm from '../pages/PostProperty/PostProperty';
import LoginPage from '../pages/LoginPage/LoginPage';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import PostsPage from '../pages/PostProperty/Posts';
import LandDetails from '../pages/Land/components/LandDetails';
import ApartmentDetails from '../pages/Apartments/components/ApartmentDetails';
import HouseForSaleDetails from '../pages/HouseForSale/components/HouseForSaleDetails';
import PromotionPage from '../pages/Promotion/Promotion';
import PromotionPaymentPage from '../pages/Promotion/Payment';
import AdminDashdoard from '../Admin/Pages/Home/AdminDashdoard';
import AdminProperties from '../Admin/Pages/Properties/Properties';
import AdminPropertyOwner from '../Admin/Pages/PropertyOwner/PropertyOwner';




const ClientRoutes = () => {
  return (
    <Routes>
       <Route path="/home" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/subscribe" element={<SubscriptionPage />} />
      <Route path="/promote" element={<PromotionPage />} />
      <Route path="/promotion-plan/:duration" element={<PromotionPaymentPage /> } />
      <Route path="/details/:id" element={<DetailedCard />} />
      <Route path="/posts" element={<PrivateRoute element={<PostsPage />} />} />
      <Route path="/post" element={<PrivateRoute element={<PropertyForm />} />} />

      <Route path="/plan/:duration" element={<PaymentPage /> } />
      <Route path="/apartment" element={<Appartments />} />
      {/* <Route path="/apartment/:id" element={<SubscribedRoute element={<ApartmentDetails />} />} /> */}
      <Route path="/apartment/:id" element={<ApartmentDetails />} />
      <Route path="/land" element={<Land />} />
      <Route path="/land/:id" element={<LandDetails />} />
      {/* <Route path="/land/:id" element={<SubscribedRoute element={<LandDetails />} />} /> */}
      <Route path="/lease" element={<Lease />} />
      <Route path="/lease/:id" element={<LeaseDetails />} />
      {/* <Route path="/lease/:id" element={<SubscribedRoute element={<LeaseDetails />} />} /> */}
      <Route path="/house-for-sale" element={<HouseForSale />} />
      <Route path="/house-for-sale/:id" element={<HouseForSaleDetails />} />
      {/* <Route path="/house-for-sale/:id" element={<SubscribedRoute element={<HouseForSaleDetails />} />} /> */}


       {/* Admin Routes */}
       <Route path="/admin/dashboard" element={<AdminDashdoard />} />
       <Route path="/admin/property" element={<AdminProperties />} />
       <Route path="/admin/property-owners" element={<AdminPropertyOwner />} />
       <Route path="/admin/permisions" element={<AdminPropertyOwner />} />
       <Route path="/admin/finance" element={<AdminPropertyOwner />} />


      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default ClientRoutes;
