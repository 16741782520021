import React from 'react';
import { Typography, Box } from '@mui/material';

const PropertyOwner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f0f0f0"
    >
      <Typography variant="h1" color="textSecondary">
        Under Construction
      </Typography>
    </Box>
  );
};

export default PropertyOwner;
