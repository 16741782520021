import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faMap, faHome, faHouse, faBuilding } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap; 
`;

const Card = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  height: 250px; 
  width: 250px; 
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
`;

const StatIcon = styled.div`
  margin-right: 10px;
`;

const StatText = styled.div`
  font-family: 'Arial', sans-serif; 
  font-size: 18px;
  font-weight: 600; 
`;

const Value = styled.span`
  color: blue; 
  font-size: 24px; 
`;

const MainCard = ({ icon, title, value }) => {
  let iconComponent;
  let iconColor = '#FFD700';

  switch (icon) {
    case 'fa-users':
      iconComponent = <FontAwesomeIcon icon={faUsers} size="2x" />;
      break;
    case 'fa-land':
      iconComponent = <FontAwesomeIcon icon={faMap} size="2x" />;
      break;
    case 'fa-rent':
      iconComponent = <FontAwesomeIcon icon={faHome} size="2x" />;
      break;
    case 'fa-apartment':
      iconComponent = <FontAwesomeIcon icon={faBuilding} size="2x" />;
      break;
      case 'fa-house':
        iconComponent = <FontAwesomeIcon icon={faHouse} size="2x" />;
        break;
    default:
      iconComponent = null;
  }

  return (
    <Card>
      <CardBody>
        <StatIcon>
          {React.cloneElement(iconComponent, { style: { color: iconColor } })}
        </StatIcon>
        <StatText>
          {title} <br />
          <Value>{value}</Value>
        </StatText>
      </CardBody>
    </Card>
  );
};

const Home = () => {
  const totalUsers = 2000;
  const totalLand = 1000;
  const totalLease = 50;
  const totalApartment = 500;
  const totalHouseForSale = 300;

  return (
    <div style={{ marginTop: 60, marginLeft: -10 }}>
     <h1 style={{ textAlign: 'left', marginLeft: '10px', fontSize: '2.3rem', color: '#9E9E9E' }}>Dashboard</h1>
      <Container style={{ marginInline: 50 }}>
        <MainCard icon="fa-users" title="Total Users" value={totalUsers} />
        <MainCard icon="fa-land" title="Total Plots" value={totalLand} />
        <MainCard icon="fa-rent" title="Total Houses For Rent" value={totalLease} />
        <MainCard icon="fa-apartment" title="Total Apartments" value={totalApartment} />
        <MainCard icon="fa-house" title="Total Houses for Sale" value={totalHouseForSale} />
      </Container>
    </div>
  );
};

export default Home;
