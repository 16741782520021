
// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';

// const Search = ({ placeholder, onSearch }) => {
//   return (
//     <div className="flex justify-center items-center w-full px-4">
//       <div className="flex items-center w-full lg:w-[50%]">
//         <div className="flex pl-4 sm:pl-6 md:pl-8 lg:pl-10 items-center bg-white rounded-md flex-grow shadow-md">
//           <input
//             type="text"
//             placeholder={placeholder}
//             className="flex-grow p-2 bg-white outline-none text-sm sm:text-base"
//             onChange={(e) => onSearch(e.target.value)}
//           />
//           <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[70px] flex items-center justify-center rounded-md">
//             <FontAwesomeIcon 
//               icon={faSearch} 
//               className="text-white" 
//               size="lg" 
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;








// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

// const Search = ({ placeholder, onSearch, onFilterType, onFilterPrice }) => {
//   const [minPrice, setMinPrice] = useState('');
//   const [maxPrice, setMaxPrice] = useState('');
//   const [selectedType, setSelectedType] = useState('');

//   // Handle type change
//   const handleTypeChange = (e) => {
//     setSelectedType(e.target.value);
//     onFilterType(e.target.value);
//   };

//   // Handle price change
//   const handlePriceChange = () => {
//     onFilterPrice(minPrice, maxPrice);
//   };

//   return (
//     <div className="flex justify-center items-center w-full px-4">
//       <div className="flex items-center w-full lg:w-[70%] space-x-4">
//         {/* Type Filter */}
//         <select
//           className="bg-white p-2 rounded-md shadow-md text-sm sm:text-base"
//           value={selectedType}
//           onChange={handleTypeChange}
//         >
//           <option value="">All Types</option>
//           <option value="land">Land</option>
//           <option value="rent">Rent</option>
//           <option value="house-for-sale">House for Sale</option>
//           <option value="apartment">Apartment</option>
//         </select>

//         {/* Search Input */}
//         <div className="flex pl-4 sm:pl-6 md:pl-8 lg:pl-10 items-center bg-white rounded-md flex-grow shadow-md">
//           <input
//             type="text"
//             placeholder={placeholder}
//             className="flex-grow p-2 bg-white outline-none text-sm sm:text-base"
//             onChange={(e) => onSearch(e.target.value)}
//           />
//           <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[70px] flex items-center justify-center rounded-md">
//             <FontAwesomeIcon
//               icon={faSearch}
//               className="text-white"
//               size="lg"
//             />
//           </div>
//         </div>

//         {/* Price Filter */}
//         <div className="flex space-x-2 items-center">
//           <input
//             type="number"
//             placeholder="Min Price"
//             className="p-2 rounded-md shadow-md text-sm sm:text-base"
//             value={minPrice}
//             onChange={(e) => setMinPrice(e.target.value)}
//           />
//           <input
//             type="number"
//             placeholder="Max Price"
//             className="p-2 rounded-md shadow-md text-sm sm:text-base"
//             value={maxPrice}
//             onChange={(e) => setMaxPrice(e.target.value)}
//           />
//           <button
//             className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
//             onClick={handlePriceChange}
//           >
//             Apply
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;





// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

// const Search = ({ placeholder, onSearch, onFilterType, onFilterPrice }) => {
//   const [minPrice, setMinPrice] = useState('');
//   const [maxPrice, setMaxPrice] = useState('');
//   const [selectedType, setSelectedType] = useState('');

//   // Handle type change
//   const handleTypeChange = (e) => {
//     setSelectedType(e.target.value);
//     onFilterType(e.target.value);
//   };

//   // Handle price change
//   const handlePriceChange = () => {
//     onFilterPrice(minPrice, maxPrice);
//   };

//   return (
//     <div className="flex flex-col justify-center items-center w-full px-4">
//       {/* Search Input at the Top */}
//       <div className="flex items-center bg-white rounded-md shadow-md w-full lg:w-[70%] mb-4">
//         <input
//           type="text"
//           placeholder={placeholder}
//           className="flex-grow p-2 bg-white outline-none text-sm sm:text-base"
//           onChange={(e) => onSearch(e.target.value)}
//         />
//         <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[70px] flex items-center justify-center rounded-md">
//           <FontAwesomeIcon
//             icon={faSearch}
//             className="text-white"
//             size="lg"
//           />
//         </div>
//       </div>

//       {/* Filters for Type and Price */}
//       <div className="flex items-center w-full lg:w-[70%] space-x-4">
//         {/* Type Filter - Icon Trigger */}
//         <div className="relative">
//           <button
//             className="bg-white p-2 rounded-md shadow-md text-sm sm:text-base flex items-center"
//             onClick={() => {
//               const select = document.getElementById('type-select');
//               if (select) select.classList.toggle('hidden');
//             }}
//           >
//             <span>{selectedType || 'All Types'}</span>
//             <FontAwesomeIcon icon={faSearch} className="ml-2" />
//           </button>
//           <select
//             id="type-select"
//             className="absolute top-full left-0 bg-white p-2 rounded-md shadow-md text-sm sm:text-base hidden"
//             value={selectedType}
//             onChange={handleTypeChange}
//           >
//             <option value="">All Types</option>
//             <option value="land">Land</option>
//             <option value="rent">Rent</option>
//             <option value="house-for-sale">House for Sale</option>
//             <option value="apartment">Apartment</option>
//           </select>
//         </div>

//         {/* Price Filter */}
//         <div className="flex space-x-2 items-center">
//           <input
//             type="number"
//             placeholder="Min Price"
//             className="p-2 rounded-md shadow-md text-sm sm:text-base"
//             value={minPrice}
//             onChange={(e) => setMinPrice(e.target.value)}
//           />
//           <input
//             type="number"
//             placeholder="Max Price"
//             className="p-2 rounded-md shadow-md text-sm sm:text-base"
//             value={maxPrice}
//             onChange={(e) => setMaxPrice(e.target.value)}
//           />
//           <button
//             className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
//             onClick={handlePriceChange}
//           >
//             Apply
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;











import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = ({ placeholder, onSearch, onFilterType, onFilterPrice }) => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    onFilterType(e.target.value);
  };

  const handlePriceChange = () => {
    onFilterPrice(minPrice, maxPrice);
  };
  const resetPrice = () => {
    onFilterPrice('', '');
    setMinPrice('');
    setMaxPrice('');
  };

  return (
    <div className="flex flex-col justify-center items-center w-full px-4">
      <div className="flex items-center bg-white rounded-md shadow-md w-full lg:w-[70%] mb-4">
        <input
          type="text"
          placeholder={placeholder}
          className="flex-grow p-2 pl-4 md:pl-12 bg-white outline-none text-sm sm:text-base"
          onChange={(e) => onSearch(e.target.value)}
        />
        <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[60px] flex items-center justify-center rounded-md">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-white"
            size="lg"
          />
        </div>
      </div>
      <div className="flex w-full md:w-[40%] items-center justify-center mx-auto space-x-4">
      <div className="flex flex-col">
        <select
          className="bg-white p-2 rounded-md mt-2 shadow-md text-sm sm:text-base"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <option value="">All Types</option>
          <option value="Land">Land</option>
          <option value="Rent">Rent</option>
          <option value="HouseForSale">House for Sale</option>
          <option value="Apartment">Apartment</option>
        </select>
      </div>

      <div className="flex flex-col w-full md:flex-row space-y-2 space-x-2">
        <div className="flex flex-col md:flex-row space-y-2 space-x-2">
          <input
            type="number"
            placeholder="Min Price"
            className="p-2 rounded-md shadow-md text-sm sm:text-base"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Max Price"
            className="p-2 rounded-md shadow-md text-sm sm:text-base"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div>
        <div className='flex justify-between space-x-2'>
        <button
          className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
          onClick={handlePriceChange}
        >
          Apply
        </button>
        <button
          className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
          onClick={resetPrice}
        >
          Reset
        </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Search;
