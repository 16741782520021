// import React from 'react';
// import { useNavigate } from 'react-router';
// import PropertyCard from './components/PropertyCard';

// const PostsPage = () => {
//   const navigate = useNavigate();

//   const postsData = [
//     {
//       id: 1,
//       type: 'Sale',
//       price: '$350,000',
//       location: '123 Main St, Springfield, IL',
//       beds: 4,
//       baths: 2,
//       type:'Rent',
//       size: 2500,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'John Doe',
//       promoted: true,
//     },
//     {
//       id: 2,
//       type: 'Rent',
//       price: '$2,000/mo',
//       location: '456 Elm St, Springfield, IL',
//       beds: 3,
//       type:'HouseForSale',
//       baths: 2,
//       size: 1800,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Jane Smith',
//       promoted: false,
//     },
//     {
//       id: 3,
//       type: 'Sale',
//       price: '$450,000',
//       location: '789 Oak St, Springfield, IL',
//       beds: 5,
//       type: 'Land',
//       baths: 3,
//       size: 3000,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Alice Johnson',
//       promoted: false,
//     },
//     {
//       id: 3,
//       type: 'Sale',
//       price: '$450,000',
//       location: '789 Oak St, Springfield, IL',
//       beds: 5,
//       type: 'Apartment',
//       baths: 3,
//       size: 3000,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Alice Johnson',
//       promoted: false,
//     },
//   ];


//   const createPost = () => {
//     navigate('/post'); 
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <div className="flex justify-between items-center mb-8">
//         <h1 className="text-3xl font-bold">Posts</h1>
//         <button
//           onClick={createPost}
//           className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
//         >
//           Create Post
//         </button>
//       </div>

//       <div className="space-y-4">
//         {postsData.map((post) => (
//           <PropertyCard key={post.id} data={post} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PostsPage;







// import React from 'react';
// import { useNavigate } from 'react-router';
// import LeaseCardX from './components/LeaseCardX';
// import LandCardX from './components/LandCardX';
// import ApartmentCardX from './components/ApartmentCardX';
// import HouseForSaleCardX from './components/HouseForSaleCardX';
// import { useAuth } from '../../context/authContext';
// import { getPropertiesByPublisher } from '../../utils/propertyFunc';

// const PostsPage = () => {
//   const navigate = useNavigate();
//   const { profile } = useAuth();
//   console.log('Profile: ', profile);

//   const postsData = [
//     {
//       id: 1,
//       type: 'Sale',
//       price: '$350,000',
//       location: '123 Main St, Springfield, IL',
//       beds: 4,
//       baths: 2,
//       type: 'Rent',
//       size: 2500,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'John Doe',
//       promoted: true,
//     },
//     {
//       id: 2,
//       type: 'Rent',
//       price: '$2,000/mo',
//       location: '456 Elm St, Springfield, IL',
//       beds: 3,
//       type: 'HouseForSale',
//       baths: 2,
//       size: 1800,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Jane Smith',
//       promoted: false,
//     },
//     {
//       id: 3,
//       type: 'Sale',
//       price: '$450,000',
//       location: '789 Oak St, Springfield, IL',
//       beds: 5,
//       type: 'Land',
//       baths: 3,
//       size: 3000,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Alice Johnson',
//       promoted: false,
//     },
//     {
//       id: 4,
//       type: 'Sale',
//       price: '$450,000',
//       location: '789 Oak St, Springfield, IL',
//       beds: 5,
//       type: 'Apartment',
//       baths: 3,
//       size: 3000,
//       image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
//       agent: 'Alice Johnson',
//       promoted: false,
//     },
//   ];

//   const createPost = () => {
//     navigate('/post'); 
//   };

//   const renderCardComponent = (post) => {
//     switch (post.type) {
//       case 'HouseForSale':
//         return <HouseForSaleCardX key={post.id} data={post} />;
//       case 'Apartment':
//         return <ApartmentCardX key={post.id} data={post} />;
//       case 'Land':
//         return <LandCardX key={post.id} data={post} />;
//         case 'Rent':
//           return <LeaseCardX key={post.id} data={post} />;
//       default:
//         return <>No card fitting</>;  
//     }
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <div className="flex justify-between items-center mb-8">
//         <h1 className="text-3xl font-bold">Posts</h1>
//         <button
//           onClick={createPost}
//           className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
//         >
//           Create Post
//         </button>
//       </div>

//       <div className="space-y-4">
//         {postsData?.map((post) => renderCardComponent(post))}
//       </div>
//     </div>
//   );
// };

// export default PostsPage;






import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LeaseCardX from './components/LeaseCardX';
import LandCardX from './components/LandCardX';
import ApartmentCardX from './components/ApartmentCardX';
import HouseForSaleCardX from './components/HouseForSaleCardX';
import { useAuth } from '../../context/authContext';
import { getPropertiesByPublisher } from '../../utils/propertyFunc';

const PostsPage = () => {
  const navigate = useNavigate();
  const { profile } = useAuth();
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log('Profile:',profile);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        if (profile) {
          setLoading(true);
          const properties = await getPropertiesByPublisher(profile.sub);
          setPostsData(properties);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, [profile]);

  const createPost = () => {
    navigate('/post');
  };

  const renderCardComponent = (post) => {
    switch (post.type) {
      case 'HouseForSale':
        return <HouseForSaleCardX key={post.id} data={post} />;
      case 'Apartment':
        return <ApartmentCardX key={post.id} data={post} />;
      case 'Land':
        return <LandCardX key={post.id} data={post} />;
      case 'Rent':
        return <LeaseCardX key={post.id} data={post} />;
      default:
        return <>No card fitting</>;
    }
  };

  if(loading) {
    return (
      <div className='flex justify-center align-center items-center'>Loading...</div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Your Posts</h1>
        <button
          onClick={createPost}
          className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
        >
          Create Post
        </button>
      </div>

      <div className="space-y-4">
        {postsData.length > 0 ? (
          postsData.map((post) => renderCardComponent(post))
        ) : (
          <p>No posts available</p>
        )}
      </div>
    </div>
  );
};

export default PostsPage;
