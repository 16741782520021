

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { getAllPromotionPlans } from "../../utils/promotionFunc";

const PromotionPage = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const loc = useLocation();
  const { propertyData } = loc.state || {};

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = await getAllPromotionPlans();
        setPlans(data);
      } catch (error) {
        console.error("Error loading subscription plans:", error);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = (plan) => {
    navigate(`/promotion-plan/${plan.durationInDays}`, { state: { plan, from: loc?.state?.from?.pathname, propertyId: propertyData?.id } });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center md:py-12 bg-gray-100 p-8">
      <div className="flex items-center justify-between mb-8">
        <button 
          onClick={handleBackClick}
          className="flex items-center hidden md:block md:mr-80 text-gray-500 hover:text-gray-900 transition duration-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </button>
        <h1 className="text-2xl text-center md:text-4xl font-bold flex-1">
          Choose Property Viewing Subscription
        </h1>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className="p-10 w-[340px] md:p-12 bg-white shadow-lg rounded-lg text-center transform hover:scale-105 transition-transform duration-300">
            <h2 className="text-2xl md:text-3xl font-semibold mb-6">{plan?.durationInDays} Days</h2>
            <p className="text-2xl md:text-3xl mb-6">Price: {plan?.price} TZS</p>
            <ul className="text-lg md:text-xl text-gray-700 mb-8">
              {plan?.features?.map((feature, i) => (
                <li key={i} className="mb-2">
                  <FontAwesomeIcon icon={faCheck} className="mr-3" />
                  {feature}
                </li>
              ))}
            </ul>
            <button 
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-6 rounded-full text-lg md:text-xl"
              onClick={() => handleSelectPlan(plan)}
            >
              Select Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PromotionPage;
