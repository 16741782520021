import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHeart, faMapMarkerAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router';
import { getTruncatedAddress } from '../../../utils/getTruncatedAddress';
import { getPictureByName } from '../../../utils/getPictureByName';

const LandCardX = ({ data }) => {
  const typeColor = data.type !== 'Rent' ? 'green' : 'yellow';
  const [isLiked, setIsLiked] = React.useState(false);
  const [isBooked, setIsBooked] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPromoted, setIsPromoted] = useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  useEffect(()=>{
    setIsPromoted(new Date(data?.promotionEndDate) <= new Date());
  },[data]);

  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/land/${data.id}`, { state: { data } });
  };

  const toggleBooked = () => {
    setIsBooked(!isBooked);
  };
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const promoteProperty = () => {
    navigate('/promote', { state: { propertyData: data } });
  }

  const handleEdit = (e) => {
    e.stopPropagation();
    console.log('Edit clicked');
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    console.log('Delete clicked');
  };


  return (
    <div
      className="border-2 relative rounded-lg p-3 w-60 md:w-full lg:w-full shadow-sm h-full transition-transform duration-300 hover:shadow-lg hover:bg-opacity-90"
      style={{ width: '100%'}}
      onClick={()=>{}}
    >
       <div className="absolute top-2 right-2 bg-gray-400 rounded-full">
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="text-gray-800 cursor-pointer p-2 hover:text-gray-600"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-28 bg-white rounded-lg shadow-lg z-10">
              <ul className="py-1">
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleEdit}
                >
                  Edit
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={goToDetails}
                >
                  View More
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
      <div className="flex flex-col lg:flex-row">
        <div className="relative lg:w-2/5">
        {!data?.promotionEndDate && (
          <button
            className="absolute top-2 left-2 bg-red-500 text-white text-xs rounded-lg px-3 py-1 z-10 hover:opacity-75"
            onClick={() => promoteProperty(data)} 
          >
            Promote
          </button>
        )}
      {data?.promotionEndDate >= new Date() && (
      <button
        className="absolute top-2 left-2 bg-red-500 text-white text-xs rounded-lg px-3 py-1 z-10 hover:opacity-75"
        onClick={() => promoteProperty(data)} 
      >
        Promote
      </button>
    )}
        <img
          src={getPictureByName(data?.media, 'picha_mbele')}
          alt="Property"
          className="w-full h-46 object-cover rounded-lg"
          style={{ height: '350px', objectFit: 'cover' }}
        />
        </div>

        <div className="lg:w-3/5 lg:pl-4 flex flex-col justify-between">
        <div className="absolute md:hidden top-2 right-2 bg-gray-400 rounded-full">
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="text-gray-800 cursor-pointer p-2 hover:text-gray-600"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-28 bg-white rounded-lg shadow-lg z-10">
              <ul className="py-1">
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleEdit}
                >
                  Edit
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={goToDetails}
                >
                  View More
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
          <div className="mt-4 lg:mt-0">
          <p className="text-xl font-medium w-700" style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}>
          Title: {data?.title?.length > 52 ? `${data.title.slice(0, 52)}...` : data.title || 'No title'}
          </p>
          <p className="text-xl font-medium" style={{ fontFamily: 'Lato, sans-serif' }}>
            Description: {data?.title?.length > 52 ? `${data.title.slice(0, 52)}...` : data?.description}
          </p>
            <div className="flex justify-between items-center mb-2">
              <div className={`text-${typeColor}-500 flex items-center`}>
                <div className={`w-2 h-2 bg-${typeColor}-500 rounded-full mr-2`}></div>
                <p className={`text-${typeColor} text-sm`}>{data.type}</p>
              </div>
      
            </div>
            <p className="text-md font-bold">{data.lotPrice} TZS</p>
            <div className="flex items-center text-xs text-gray-500 mt-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
              <p>{getTruncatedAddress(data?.propertyAddress)}</p>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/length.svg`} alt="Size" className="w-4 h-4" />
                <p className="ml-1 text-xs">Lot Width: {data.lotWidth} m</p>
              </div>
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/length.svg`} alt="Size" className="w-4 h-4" />
                <p className="ml-1 text-xs">Lot Length: {data.lotLength} m</p>
              </div>
            </div>
            <p className="text-xs text-gray-500 mt-2">Listed by {data?.ownerName?.length > 15 ? `${data?.ownerName.slice(0, 12)}...` : data?.ownerName}</p>
            <p className='mt-2'>Other Pictures</p>
            <div className='flex flex-row justify-center gap-4 mt-2'>
              <div>
                  <img
                  src={getPictureByName(data?.media, 'picha_chumba')}
                  alt="Property"
                  className="w-full h-46 object-cover rounded-lg"
                  style={{ height: '140px', width: '200px', objectFit: 'cover' }}
                />
              </div>
              <div>
                  <img
                  src={getPictureByName(data?.media, 'picha_sebule')}
                  alt="Property"
                  className="w-full h-46 object-cover rounded-lg"
                  style={{ height: '140px', width: '200px', objectFit: 'cover' }}
                />
              </div>
              <div>
                  <img
                  src={getPictureByName(data?.media, 'picha_jiko')}
                  alt="Property"
                  className="w-full h-46 object-cover rounded-lg"
                  style={{ height: '140px', width: '200px', objectFit: 'cover' }}
                />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandCardX;
