import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Tabs, Tab } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import Drawer from '@mui/material/Drawer';

const propertiesData = {
  land: [
    { id: 1, name: 'Land Plot A', location: 'Region A', price: '500,000 TZS', status: 'Pending' },
    { id: 2, name: 'Land Plot B', location: 'Region B', price: '750,000 TZS', status: 'Approved' },
  ],
  lease: [
    { id: 3, name: 'Lease Property A', location: 'Region C', price: '300,000 TZS', status: 'Pending' },
    { id: 4, name: 'Lease Property B', location: 'Region D', price: '400,000 TZS', status: 'Disapproved' },
  ],
  houseForSale: [
    { id: 5, name: 'House for Sale A', location: 'Region E', price: '1,500,000 TZS', status: 'Pending' },
    { id: 6, name: 'House for Sale B', location: 'Region F', price: '2,000,000 TZS', status: 'Approved' },
  ],
  apartment: [
    { id: 7, name: 'Apartment A', location: 'Region G', price: '1,200,000 TZS', status: 'Pending' },
    { id: 8, name: 'Apartment B', location: 'Region H', price: '1,800,000 TZS', status: 'Disapproved' },
  ],
};

const Properties = () => {
  const [selectedTab, setSelectedTab] = useState('land');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewDetail = (property) => {
    setSelectedProperty(property);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedProperty(null);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>Properties Management</Typography>

      {/* Top Menu Tabs */}
      {/* <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Land" value="land" />
        <Tab label="Lease" value="lease" />
        <Tab label="House For Sale" value="houseForSale" />
        <Tab label="Apartment" value="apartment" />
      </Tabs> */}
       <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Property Tabs"
        centered
        sx={{
          '& .MuiTab-root': {
            fontSize: '1rem', 
            fontWeight: 600,
            padding: '12px 24px', 
            marginTop: '1.5rem',
            minWidth: '120px', 
          },
          '& .MuiTab-iconWrapper': {
            fontSize: '1.5rem', 
          },
        }}
      >
        <Tab label="Land" value="land" />
        <Tab label="Lease" value="lease" />
        <Tab label="House For Sale" value="houseForSale" />
        <Tab label="Apartment" value="apartment" />
      </Tabs>

      {/* Table for displaying properties */}
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {propertiesData[selectedTab].map((property) => (
              <TableRow key={property.id}>
                <TableCell>{property.id}</TableCell>
                <TableCell>{property.name}</TableCell>
                <TableCell>{property.location}</TableCell>
                <TableCell>{property.price}</TableCell>
                <TableCell>{property.status}</TableCell>
                <TableCell>
                  <IconButton color="success" onClick={() => alert('Approve action')}>
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleViewDetail(property)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => alert('Disapprove action')}>
                    <CancelIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Side Panel Drawer for viewing details */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ width: 300, padding: 20 }}>
          {selectedProperty && (
            <>
              <Typography variant="h6">Property Details</Typography>
              <Typography>ID: {selectedProperty.id}</Typography>
              <Typography>Name: {selectedProperty.name}</Typography>
              <Typography>Location: {selectedProperty.location}</Typography>
              <Typography>Price: {selectedProperty.price}</Typography>
              <Typography>Status: {selectedProperty.status}</Typography>
              <Button onClick={handleCloseDrawer} variant="contained" color="primary" fullWidth>
                Close
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Properties;
