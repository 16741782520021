

import React, { createContext, useState, useEffect, useContext } from 'react';
import { getProfile, loginUser, logoutUser } from '../utils/authFunc';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [token, setToken] = useState();
  const [showPopover, setShowPopover] = useState(null); 

  const [isSubscribed, setIsSubscribed] = useState(false);

  const fetchProfile = async () => {
    try {
      const profileData = await getProfile();
      console.log("Profile Data in context");
      console.log(profileData);
      if (profileData) {
        setProfile(profileData);
        setIsLoggedIn(true); 
      } else {
        setIsLoggedIn(false); 
      }
    } catch (error) {
      console.error("Failed to fetch profile", error);
      setIsLoggedIn(false); 
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials) => {
    try {
      await loginUser(credentials,{setToken});
      const prof = await fetchProfile(); 
      return prof;
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await logoutUser();
      setProfile(null);
      setIsLoggedIn(false); 
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    fetchProfile(); 
  }, []);

  const value = {
    profile,
    setProfile,
    isSubscribed,
    setIsSubscribed,
    login,
    logout,
    isLoggedIn, 
    loading,
    token,
    setToken,
    showPopover,
    setShowPopover
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
