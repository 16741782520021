
import React, { useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHeart, faMapMarkerAlt, faArrowLeft, faPhone, faComments } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'; 
import { getTruncatedAddress } from '../../../utils/getTruncatedAddress';
import { getPictureByName } from '../../../utils/getPictureByName';

const HouseForSaleDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;

  const [showContacts, setShowContacts] = useState(false);

  const handleBackClick = () => {
    navigate(-1); 
  };

    const handleCall = () => {
      window.open(`tel:${data?.publishedBy.phoneNumber}`);
    };
  
    const handleChat = () => {
      window.open(`https://chatapp.com/chat/${data?.publishedBy.phoneNumber}`); 
    };

    const toggleContacts = () => {
      setShowContacts(!showContacts);
    };

  return (
    <div className="w-full bg-gray-100 py-8">
      <div className="container mx-auto p-2 md:p-8">
        <button 
          onClick={handleBackClick}
          className="flex ml-6 md:ml-2 lg:ml-2 items-center text-gray-500 hover:text-gray-900 hover:ml-4 mb-6 transition duration-1200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 transition duration-1200" />
          Back
        </button>

        <div className="relative grid grid-cols-[2.3fr_1.7fr] gap-2 md:h-[500px] mt-8">
          <div className="relative h-full">
            <img
              src={getPictureByName(data?.media, 'picha_mbele')}
              alt="Main Property"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '640px' }}
            />
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-4 h-full">
            <img
              src={getPictureByName(data?.media, 'picha_nyuma')}
              alt="Property 1"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '310px' }}
            />
            <img
              src={getPictureByName(data?.media, 'picha_kulia')}
              alt="Property 2"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '310px' }}
            />
            <img
              src={getPictureByName(data?.media, 'picha_kushoto')}
              alt="Property 3"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '310px' }}
            />
            <img
              src={getPictureByName(data?.media, 'picha_sebule')}
              alt="Property 4"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '310px' }}
            />
             {/* <img
              src={getPictureByName(data?.media, 'picha_jiko')}
              alt="Property 4"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '320px' }}
            />
              <img
              src={getPictureByName(data?.media, 'picha_choo')}
              alt="Property 4"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '320px' }}
            />
               <img
              src={getPictureByName(data?.media, 'picha_chumba')}
              alt="Property 4"
              className="w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 hover:opacity-90"
              style={{ height: '320px' }}
            /> */}
          </div>
        </div>

        <div style={{marginTop: '150px'}} className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8 mt-48">
          <div className="flex flex-col space-y-6">
            <h2 className="text-3xl font-bold">{data?.title || "Title"}</h2>
            <p className="text-lg text-gray-700">{data?.description || "Description"}</p>

            <div className="flex flex-col items-start space-y-4">
              <div>
                <FontAwesomeIcon
                  icon={faBook}
                  className="border rounded-full p-3 text-xl hover:text-gray-600 cursor-pointer"
                />
                <FontAwesomeIcon
                  icon={faHeart}
                  className="border rounded-full p-3 text-xl hover:text-red-500 cursor-pointer"
                />
              </div>
              <div className={`flex items-center text-${data?.type !== 'Rent' ? 'green' : 'yellow'}-500 text-lg`}>
                <div className={`w-3 h-3 bg-${data?.type !== 'Rent' ? 'green' : 'yellow'}-500 rounded-full mr-3`}></div>
                <p className="text-xl">{data?.type}</p>
              </div>
            </div>

            <p className="text-xl font-bold mt-4">{data?.housePrice} TZS</p>
            <div className="flex items-center text-lg text-gray-500 mt-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              <p>{getTruncatedAddress(data?.propertyAddress)}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between">
            <div className="space-y-6">
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/bed.svg`} alt="Beds" className="w-6 h-6" />
                <p className="ml-3 text-lg">{data?.numberOfBedrooms} Beds</p>
              </div>
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/bathtub.svg`} alt="Baths" className="w-6 h-6" />
                <p className="ml-3 text-lg">{data?.numberOfBathrooms} Baths</p>
              </div>
               <div className="flex items-center">
             <img src={`${process.env.PUBLIC_URL}/assets/images/length.svg`} alt="Size" className="w-4 h-4" />
             <p className="ml-1 text-xs">{data.squareFootage || '--'} sqm</p>
             </div>
              <div className="flex items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/user.png`}
                  alt="Agent"
                  className="w-8 h-8 object-cover rounded-full"
                />
                <p className="ml-2 text-sm">{data?.ownerName}</p>
              </div>
            </div>
         
            {showContacts && (
                <div className='flex flex-col justify-center items-center space-y-4 mb-4'>
                <div className='flex flex-row items-center space-x-4 text-2xl'>
                  <FontAwesomeIcon icon={faPhone} className="text-green-500 fa-x" />
                  <p>{data?.publishedBy?.phoneNumber}</p>
                </div>
                
                <div className='flex flex-row items-center space-x-10'>
                  <button onClick={handleCall} className='flex items-center space-x-2 bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded text-xl'>
                    <FontAwesomeIcon icon={faPhone} className="fa-lg" />
                    <span>Call</span>
                  </button>

                  <button onClick={handleChat} className='flex items-center space-x-2 bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded text-xl'>
                    <FontAwesomeIcon icon={faComments} className="fa-lg" />
                    <span>Chat</span>
                  </button>
                </div>
              </div>
            )}

            <button onClick={toggleContacts} className="bg-yellow-500 text-black px-4 py-4 text-md rounded-lg hover:opacity-75 mt-6 md:mt-0">
              {showContacts ? 'Hide Contacts' : 'View Contacts'}
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseForSaleDetails;
