
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHeart, faCamera, faMapMarkerAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router';
import { getTruncatedAddress } from '../../../utils/getTruncatedAddress';
import { getPictureByName } from '../../../utils/getPictureByName';

const LeaseCardX = ({ data }) => {
  const typeColor = data.type !== 'Rent' ? 'green' : 'yellow';
  const [isLiked, setIsLiked] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPromoted, setIsPromoted] = useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };
  useEffect(()=>{
    setIsPromoted(new Date(data?.promotionEndDate) >= new Date());
  },[data]);

  const toggleBooked = () => {
    setIsBooked(!isBooked);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/lease/${data.id}`, { state: { data } });
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    console.log('Edit clicked');
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    console.log('Delete clicked');
  };

  const promoteProperty = () => {
    navigate('/promote', { state: { propertyData: data } });
  }
  console.log(data);

  return (
    <div
      className="flex relative flex-col md:flex-row border-2 z-0 rounded-lg p-3 w-full shadow-sm h-full transition-transform duration-300 hover:shadow-lg hover:shadow-gray-400 hover:bg-opacity-90"
      style={{ zIndex: 0 }}
      onClick={()=>{}}
    >
       <div className="absolute top-2 right-2 bg-gray-400 rounded-full">
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="text-gray-800 cursor-pointer p-2 hover:text-gray-600"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-28 bg-white rounded-lg shadow-lg z-10">
              <ul className="py-1">
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleEdit}
                >
                  Edit
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={goToDetails}
                >
                  View more
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
       
      <div className="relative w-full md:w-2/5">
      {!data?.promotionEndDate && (
          <button
            className="absolute top-2 left-2 bg-red-500 text-white text-xs rounded-lg px-3 py-1 z-10 hover:opacity-75"
            onClick={() => promoteProperty(data)} 
          >
            Promote
          </button>
        )}
      {data?.promotionEndDate >= new Date() && (
      <button
        className="absolute top-2 left-2 bg-red-500 text-white text-xs rounded-lg px-3 py-1 z-10 hover:opacity-75"
        onClick={() => promoteProperty(data)} 
      >
        Promote
      </button>
    )}
      <img
          src={getPictureByName(data?.media, 'picha_nje')}
          alt="Property"
          className="w-full h-46 object-cover rounded-lg"
          style={{ height: '350px', objectFit: 'cover' }}
        />
       
      </div>

      <div className="mt-4 w-full md:w-3/5 md:mt-0 md:ml-4">
      <div className="absolute md:hidden top-2 right-2 bg-gray-400 rounded-full">
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="text-gray-800 cursor-pointer p-2 hover:text-gray-600"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-28 bg-white rounded-lg shadow-lg z-10">
              <ul className="py-1">
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleEdit}
                >
                  Edit
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={goToDetails}
                >
                  View More
                </li>
                <li
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
        <p className="text-xl font-medium w-700" style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}>
          Title: {data?.title?.length > 52 ? `${data.title.slice(0, 52)}...` : data.title || 'No title'}
        </p>
        <p className="text-xl font-medium" style={{ fontFamily: 'Lato, sans-serif' }}>
          Description: {data?.title?.length > 52 ? `${data.title.slice(0, 52)}...` : data?.description}
        </p>
        <div className="flex justify-between items-center mb-2">
          <div className={`text-${typeColor}-500 flex items-center`}>
            <div className={`w-2 h-2 bg-${typeColor}-500 rounded-full mr-2`}></div>
            <p className={`text-${typeColor} text-sm`}>{data.type}</p>
          </div>
          {/* <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faBook}
              className={`border rounded-full p-1 hover:text-gray-600 cursor-pointer ${
                isBooked ? 'text-gray-600' : 'text-gray-400'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                toggleBooked();
              }}
            />
            <FontAwesomeIcon
              icon={isLiked ? faHeart : farHeart}
              className={`border rounded-full p-1 hover:text-red-500 cursor-pointer ${
                isLiked ? 'text-red-500' : 'text-gray-400'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                toggleLike();
              }}
            />
          </div> */}
        </div>
        <p className="text-md font-bold">{data.leasePrice} TZS</p>
        <div className="flex items-center text-xs text-gray-500 mt-2">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
          <p>{getTruncatedAddress(data?.propertyAddress)}</p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/bed.svg`}
              alt="Beds"
              className="w-4 h-4"
            />
            <p className="ml-1 text-xs">{data.numberOfBedrooms} Beds</p>
          </div>
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/bathtub.svg`}
              alt="Baths"
              className="w-4 h-4"
            />
            <p className="ml-1 text-xs">{data.numberOfBathrooms} Bath</p>
          </div>
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/length.svg`}
              alt="Size"
              className="w-4 h-4"
            />
            <p className="ml-1 text-xs">Lease Term: {data.leaseTerm} Mon</p>
          </div>
        </div>
        <p className="text-xs text-gray-500 mt-2">Listed by: {data.ownerName}</p>
        <p className='mt-2'>Other Pictures</p>
        <div className='flex flex-row justify-center gap-4 mt-2'>
          <div>
              <img
              src={getPictureByName(data?.media, 'picha_chumba')}
              alt="Property"
              className="w-full h-46 object-cover rounded-lg"
              style={{ height: '140px', width: '200px', objectFit: 'cover' }}
            />
          </div>
          <div>
              <img
              src={getPictureByName(data?.media, 'picha_sebule')}
              alt="Property"
              className="w-full h-46 object-cover rounded-lg"
              style={{ height: '140px', width: '200px', objectFit: 'cover' }}
            />
          </div>
          <div>
              <img
              src={getPictureByName(data?.media, 'picha_jiko')}
              alt="Property"
              className="w-full h-46 object-cover rounded-lg"
              style={{ height: '140px', width: '200px', objectFit: 'cover' }}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default LeaseCardX;
