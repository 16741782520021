

// import React, { useState, useEffect, useRef } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
// import LoginPopover from './LoginPopover';
// import RegisterPopover from './RegisterPopover'; 
// import 'react-toastify/dist/ReactToastify.css'; 
// import { useAuth } from '../context/authContext';

// const Header = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const navigate = useNavigate();
//   const dropdownRef = useRef(null); 
//   const target = useRef(null); 

//   const { showPopover, setShowPopover,isLoggedIn, logout,  profile } = useAuth();

//   const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

//   const handlePopoverClose = (type) => {
//     setShowPopover(type || null);
//   };

//   const handleLogout = async () => {
//       await logout();
//       setShowDropdown(false);
//       navigate('/home'); 
//   };


//   return (
//     <header className="w-full md:w-[90%]  mx-auto my-1 flex items-center justify-between p-2 bg-transparent">
//       <div className="flex items-center space-x-2" onClick={()=> navigate('/home')}>
//         <img
//           src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
//           alt="Logo"
//           onClick={() =>  navigate('/post')}
//           className="w-32 h-8 md:w-40 md:h-10"
//         />
//       </div>

//       {/* Desktop and Tablet View */}
//       <div className="hidden md:flex flex-grow items-center">
//         <nav style={{fontWeight: 500}} className="flex-grow flex text-20 justify-center space-x-7 font-roboto font-500">
        
//         </nav>
//         <button
//               onClick={() =>  navigate('/posts')}
//               className="bg-yellow-500 mr-8 text-white py-1 px-5 rounded-md"
//             >
//               POSTS
//         </button>
//         {isLoggedIn ? (
//         <div ref={dropdownRef} className="relative flex items-center space-x-2 cursor-pointer">
//         {profile.user ? (
//           <div
//             className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-500 text-white text-xl font-semibold"
//             onClick={() => setShowDropdown(!showDropdown)}
//           >
//             {profile.user.charAt(0).toUpperCase()}
//           </div>
//         ) : (
//           <img
//             src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
//             alt="Profile Avatar"
//             className="w-10 h-10 rounded-full bg-gray-500 p-1"
//             onClick={() => setShowDropdown(!showDropdown)}
//           />
//         )}
//         <FontAwesomeIcon icon={faCaretDown} onClick={() => setShowDropdown(!showDropdown)} />

//         {showDropdown && (
//           <div className="absolute right-0 mt-36 w-48 bg-white rounded-md shadow-lg py-2">
//             <div className="flex justify-end pr-2">
//               <FontAwesomeIcon 
//                 icon={faTimes} 
//                 className="text-gray-500 cursor-pointer" 
//                 onClick={() => setShowDropdown(false)} 
//               />
//             </div>
//             <button
//               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//               onClick={() => {
//                 console.log("Profile button clicked");
//                 navigate('/profile');
//                 setShowDropdown(false);
//               }}
//             >
//               Profile
//             </button>
//             <button
//               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//               onClick={handleLogout}
//             >
//               Logout
//             </button>
//           </div>
//         )}
//       </div>

//         ) : (
//           <div className="flex space-x-4">
//             <button
//               onClick={() =>  navigate('/login')}
//               className="border border-yellow-500 text-yellow-500 py-1 px-5 rounded-md bg-transparent"
//             >
//               SIGN IN
//             </button>
//           </div>
//         )}
//       </div>

//       {/* Mobile View */}
//       <div className="md:hidden flex items-center w-full justify-between">
//         {isLoggedIn ? (
//           <div ref={dropdownRef} className="flex w-full justify-center pl-22">
//             <img
//               src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
//               alt="Profile Avatar"
//               className="w-6 h-6 rounded-full ml-20"
//               onClick={() => setShowDropdown(!showDropdown)}
//             />
//             {showDropdown && (
//               <div className="absolute right-0 mt-8 w-40 bg-white rounded-md shadow-lg py-2">
//                 <div className="flex justify-end pr-2">
//                   <FontAwesomeIcon 
//                     icon={faTimes} 
//                     className="text-gray-500 cursor-pointer" 
//                     onClick={() => setShowDropdown(false)} 
//                   />
//                 </div>
//                 <button
//                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//                   onClick={() => {
//                     console.log("Profile button clicked");
//                     navigate('/profile');
//                     setShowDropdown(false);
//                   }}
//                 >
//                   Profile
//                 </button>
//                 <button
//                   className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
//                   onClick={handleLogout}
//                 >
//                   Logout
//                 </button>
//               </div>
//             )}
//           </div>
//         ) : (
//           <div className="flex w-full justify-center pl-24">
//             <button
//               onClick={() =>  navigate('/login')}
//               className="border border-yellow-500 text-sm text-yellow-500 py-1 px-5 rounded-md bg-transparent"
//             >
//               SIGN IN
//             </button>
//           </div>
//         )}
//         <button onClick={toggleMenu} className="text-yellow-500 relative mr-2">
//           <FontAwesomeIcon icon={faBars} className="text-2xl" />
//           {isMenuOpen && (
//             <div className="absolute top-full right-0 bg-white shadow-lg rounded-md w-40 py-2 flex flex-col items-center space-y-2">
//                 <NavLink
//                 to="/faq"
//                 className="text-gray-800"
//                 onClick={() =>  navigate('/register')}
//               >
//                 SIGN UP
//               </NavLink>
//               <NavLink
//                 to="/posts"
//                 className="text-gray-800"
//                 onClick={() => setIsMenuOpen(false)}
//               >
//                 POSTS
//               </NavLink>

//             </div>
//           )}
//         </button>
//       </div>

//       {/* Popovers */}
//       {showPopover === 'login' && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
//           <LoginPopover onClose={() => handlePopoverClose(null)} />
//         </div>
//       )}
//       {showPopover === 'register' && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
//           <RegisterPopover onClose={() => handlePopoverClose(null)} />
//         </div>
//       )}
//     </header>
//   );
// };

// export default Header;



import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoginPopover from './LoginPopover';
import RegisterPopover from './RegisterPopover'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useAuth } from '../context/authContext';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const dropdownRef = useRef(null); 

  const { showPopover, setShowPopover, isLoggedIn, logout, profile } = useAuth();

  useEffect(() => {
    // Initialize userName from profile when logged in
    if (isLoggedIn && profile.user) {
      setUserName(profile.user);
    }
  }, [isLoggedIn, profile]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handlePopoverClose = (type) => {
    setShowPopover(type || null);
  };

  const handleLogout = async () => {
      await logout();
      setShowDropdown(false);
      navigate('/home'); 
  };

  const handleProfileClick = () => {
    setIsDrawerOpen(true);
    setShowDropdown(false); // Close dropdown when opening drawer
  };

  const handleUpdateName = () => {
    // Handle the name update logic here (e.g., API call)
    console.log("Updated Name:", userName);
    setIsDrawerOpen(false); // Close drawer after update
  };

  return (
    <header className="w-full md:w-[90%]  mx-auto my-1 flex items-center justify-between p-2 bg-transparent">
      <div className="flex items-center space-x-2" onClick={() => navigate('/home')}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
          alt="Logo"
          onClick={() => navigate('/post')}
          className="w-32 h-8 md:w-40 md:h-10"
        />
      </div>

      {/* Desktop and Tablet View */}
      <div className="hidden md:flex flex-grow items-center">
        <nav style={{fontWeight: 500}} className="flex-grow flex text-20 justify-center space-x-7 font-roboto font-500">
          {/* Add any navigation links here */}
        </nav>
        <button
          onClick={() => navigate('/posts')}
          className="bg-yellow-500 mr-8 text-white py-1 px-5 rounded-md"
        >
          POSTS
        </button>
        {isLoggedIn ? (
          <div ref={dropdownRef} className="relative flex items-center space-x-2 cursor-pointer">
            {profile.user ? (
              <div
                className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-500 text-white text-xl font-semibold"
                onClick={handleProfileClick} // Open drawer on click
              >
                {profile.user.charAt(0).toUpperCase()}
              </div>
            ) : (
              <img
                src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
                alt="Profile Avatar"
                className="w-10 h-10 rounded-full bg-gray-500 p-1"
                onClick={handleProfileClick} // Open drawer on click
              />
            )}
            <FontAwesomeIcon icon={faCaretDown} onClick={() => setShowDropdown(!showDropdown)} />
            {showDropdown && (
              <div className="absolute right-0 mt-36 w-48 bg-white rounded-md shadow-lg py-2">
                <div className="flex justify-end pr-2">
                  <FontAwesomeIcon 
                    icon={faTimes} 
                    className="text-gray-500 cursor-pointer" 
                    onClick={() => setShowDropdown(false)} 
                  />
                </div>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleProfileClick}
                >
                  Profile
                </button>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex space-x-4">
            <button
              onClick={() => navigate('/login')}
              className="border border-yellow-500 text-yellow-500 py-1 px-5 rounded-md bg-transparent"
            >
              SIGN IN
            </button>
          </div>
        )}
      </div>

      {/* Mobile View */}
      <div className="md:hidden flex items-center w-full justify-between">
        {isLoggedIn ? (
          <div ref={dropdownRef} className="flex w-full justify-center pl-22">
            <img
              src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
              alt="Profile Avatar"
              className="w-6 h-6 rounded-full ml-20"
              onClick={handleProfileClick} // Open drawer on click
            />
            {showDropdown && (
              <div className="absolute right-0 mt-8 w-40 bg-white rounded-md shadow-lg py-2">
                <div className="flex justify-end pr-2">
                  <FontAwesomeIcon 
                    icon={faTimes} 
                    className="text-gray-500 cursor-pointer" 
                    onClick={() => setShowDropdown(false)} 
                  />
                </div>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleProfileClick}
                >
                  Profile
                </button>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex w-full justify-center pl-24">
            <button
              onClick={() => navigate('/login')}
              className="border border-yellow-500 text-sm text-yellow-500 py-1 px-5 rounded-md bg-transparent"
            >
              SIGN IN
            </button>
          </div>
        )}
        <button onClick={toggleMenu} className="text-yellow-500 relative mr-2">
          <FontAwesomeIcon icon={faBars} className="text-2xl" />
          {isMenuOpen && (
            <div className="absolute top-full right-0 bg-white shadow-lg rounded-md w-40 py-2 flex flex-col items-center space-y-2">
                <NavLink
                to="/faq"
                className="text-gray-800"
                onClick={() => navigate('/register')}
              >
                SIGN UP
              </NavLink>
              <NavLink
                to="/posts"
                className="text-gray-800"
                onClick={() => setIsMenuOpen(false)}
              >
                POSTS
              </NavLink>
            </div>
          )}
        </button>
      </div>

      {/* User Info Drawer */}
      {isDrawerOpen && (
        <div className="fixed top-0 right-0 w-80 h-full bg-white shadow-lg z-50 p-4">
          <h2 className="text-lg font-semibold mb-4">User Info---(Under construction)</h2>
          <div className="mb-2">
            <strong>Name:</strong> 
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="border border-gray-300 rounded-md px-2 py-1 w-full mt-1"
              placeholder="Enter your name"
            />
          </div>
          <div className="mb-2">
            <strong>Phone:</strong> 
            <p className="text-gray-500">Placeholder for phone number</p>
          </div>
          <button 
            onClick={handleUpdateName}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
          >
            Update Name
          </button>
          <button 
            onClick={() => setIsDrawerOpen(false)} 
            className="absolute top-2 right-2"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}

      {/* Popovers */}
      {showPopover === 'login' && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <LoginPopover onClose={() => handlePopoverClose('')} />
        </div>
      )}
      {showPopover === 'register' && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <RegisterPopover onClose={() => handlePopoverClose('')} />
        </div>
      )}
    </header>
  );
};

export default Header;
